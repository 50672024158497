import {
  ValidationProvider as vp,
  ValidationObserver as vc,
  configure,
  extend,
} from 'vee-validate/dist/vee-validate.full.esm'

configure({
  classes: {
    invalid: 'error',
  },
  mode: 'eager',
})

extend('url', {
  validate: (value) => {
    const baseUrlValidation =
      /https:\/\/[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?/
    return baseUrlValidation.test(value)
  },
  message:
    "This link is incorrectly formed, double check that it's using HTTPS and doesn't have any typos",
})

export const ValidationObserver = vc
export const ValidationProvider = vp
