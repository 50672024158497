<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        field: true,
        info: this.info,
        inline: this.inline,
        error: this.error,
        success: this.success,
        disabled: this.disabled,
        required: this.required,
      }
    },
  },
}
</script>
