<template>
  <validation-observer v-slot="{ valid, handleSubmit, reset }" slim>
    <!-- HACK: reset event requires a slot scoped method and a component defined method, easiest way to merge them is
    this two line method call but it is not typically advised  -->
    <form
      :id="id"
      ref="form"
      :key="`form-${refreshKey}`"
      :class="classes"
      @submit.prevent="handleSubmit(onSubmit)"
      @reset.prevent="
        reset()
        refresh()
      "
    >
      <slot :valid="valid" />
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from '@/extensions/v2/validation'

export default {
  components: {
    ValidationObserver,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['medium', 'big', 'huge'].includes(value)
      },
    },
  },
  data() {
    return {
      refreshKey: 0,
    }
  },
  computed: {
    classes() {
      return {
        ui: true,
        form: true,
        [this.size]: true,
      }
    },
  },
  methods: {
    onSubmit() {
      const submission = new Map()
      // for of is recommended for manipulating FormData
      // eslint-disable-next-line no-restricted-syntax
      for (const entry of new FormData(this.$refs.form).entries()) {
        const [key] = entry
        let [, value] = entry

        // dropdown values in FormData can be stringified objects
        // FormData encodes the quote marks so when those are present, we decode and parse
        // this also handles dropdown values that have been prepended with § by us
        if (value.match(/&quot;/) || value.startsWith('§')) {
          value = JSON.parse(value.replace(/&quot;/g, '"').slice(1))
        }

        submission.set(key, value || null)
      }
      this.$emit('submit', submission)
    },
    refresh() {
      this.refreshKey += 1
    },
  },
}
</script>
